<template>
	<div @click.self="shopShow = false" style="overflow-x: hidden" v-loading.fullscreen.lock="fullscreenLoading">
		<Navigation />
		<!-- 分类区 -->
		<div class="bg_box">
			<div class="ct_box">
				<Nav title="5" />
				<!-- 门户类型 -->
				<div class="type2 flex">
					<p>行业类型：</p>
					<ul class="flex ul2">
						<li :class="ChannelType.title == '不限' ? 'active' : ''" @click="ChannelShow('不限', 0)">不限</li>
						<li :class="ChannelType.title == item ? 'active' : ''" v-for="(item, index) in ChannelList"
							:key="index" @click="ChannelShow(item, index)">
							{{ item }}
						</li>
					</ul>
				</div>

				<div class="type2 flex">
					<p>所属区域：</p>
					<ul class="flex ul2">
						<li :class="TerritoryType.title == '不限' ? 'active' : ''" @click="TerritoryShow('不限', 0)">不限</li>
						<li :class="TerritoryType.title == item ? 'active' : ''" v-for="(item, index) in TerritoryList"
							:key="index" @click="TerritoryShow(item, index)">
							{{ item }}
						</li>
					</ul>
				</div>
				<div class="type2 flex">
					<p>V认证：</p>
					<ul class="flex ul2">
						<li :class="VAuthType.title == '不限' ? 'active' : ''" @click="VAuthShow('不限', '-1')">不限</li>
						<li :class="VAuthType.title == item ? 'active' : ''" v-for="(item, index) in VAuthList"
							:key="index" @click="VAuthShow(item, index)">
							{{ item }}
						</li>
					</ul>
				</div>
				<div class="type2 flex">
					<p>参考粉丝数：</p>
					<ul class="flex ul2">
						<li :class="FansNumType.title == '不限' ? 'active' : ''" @click="FansNumShow('不限', 0)">不限</li>
						<li :class="FansNumType.title == item ? 'active' : ''" v-for="(item, index) in FansNumList"
							:key="index" @click="FansNumShow(item, index)">
							{{ item }}
						</li>
					</ul>
				</div>
				<div class="type2 flex">
					<p>直发价格区间：</p>
					<ul class="flex ul2">
						<li :class="MoneyType.title == '不限' ? 'active' : ''" @click="MoneyShow('不限', 0)">不限</li>
						<li :class="MoneyType.title == item ? 'active' : ''" v-for="(item, index) in MoneyList"
							:key="index" @click="MoneyShow(item, index)">
							{{ item }}
						</li>
					</ul>
				</div>
				<div class="type2 flex">
					<p>转发价格区间：</p>
					<ul class="flex ul2">
						<li :class="ForwardMoneyType.title == '不限' ? 'active' : ''" @click="ForwardMoneyShow('不限', 0)">
							不限
						</li>
						<li :class="ForwardMoneyType.title == item ? 'active' : ''"
							v-for="(item, index) in ForwardMoneyList" :key="index"
							@click="ForwardMoneyShow(item, index)">
							{{ item }}
						</li>
					</ul>
				</div>
				<div class="type2 flex">
					<p>是否官方：</p>
					<ul class="flex ul2">
						<li @click="isOfficialShow('不限')" :class="officialType == '不限' ? 'active' : ''">
							不限
						</li>
						<li v-for="(item, index) in officialList" :key="index"
							:class="officialType == item ? 'active' : ''" @click="isOfficialShow(item)">{{ item }}</li>
					</ul>
				</div>
				<div class="type2 flex">
					<p>特殊类目：</p>
					<ul class="flex ul2">
						<li :class="SpecialType == '不限' ? 'active' : ''" @click="SpecialShow('不限')">不限</li>
						<li v-show="SpecialList.is_pub_weekend" :class="SpecialType == '周末可发' ? 'active' : ''"
							@click="SpecialShow('周末可发')">
							{{ SpecialList.is_pub_weekend }}
						</li>
						<!-- 新加特殊类目项:是否可加配图 是否可发视频 -->
						<li v-show="SpecialList.is_pub_video" :class="SpecialType == '可发视频' ? 'active' : ''"
							@click="SpecialShow('可发视频')">
							{{ SpecialList.is_pub_video }}
						</li>
					</ul>
				</div>
				<div class="type3 flex">
					<p>排序：</p>
					<ul class="flex ul2">
						<li @click="StateShow('默认')" :class="SortType.title == '默认' ? 'active' : ''">
							默认
						</li>
						<li v-if="SortList.member_price" @click="StateShow('直发价格', 'medium_price')" :id="SortType.title == '直发价格' && MoneyState == 1 ? 'activeTrue'
		: SortType.title == '直发价格' && MoneyState == 2 ? 'activeFalse' : ''"
							:class="SortType.title == '直发价格' ? 'active' : ''">
							直发价格
						</li>
						<li v-if="SortList.member_forward_price" @click="StateShow('转发价格', 'medium_price')" :id="SortType.title == '转发价格' && ForwardMoneyState == 1 ? 'activeTrue'
		: SortType.title == '转发价格' && ForwardMoneyState == 2 ? 'activeFalse' : ''"
							:class="SortType.title == '转发价格' ? 'active' : ''">
							转发价格
						</li>
					</ul>
				</div>
				<div class="type4 flex">
					<p class="type_title_n">媒介名称：</p>
					<el-input @keyup.enter.native="page = 1; ListFn()" v-model="mediaName"
						placeholder="请输入编号或名称"></el-input>
					<div class="flex link_box">
						<p>可发内链：</p>
						<el-select v-model="link" placeholder="请选择">
							<el-option v-for="(item, index) in linkList" :key="index" :label="item" :value="item">
							</el-option>
						</el-select>
					</div>
					<div class="flex link_box">
						<p>可发联系方式：</p>
						<el-select v-model="relation" placeholder="请选择">
							<el-option v-for="(item, index) in linkList" :key="index" :label="item" :value="item">
							</el-option>
						</el-select>
					</div>
					<button class="sf_but" type="primary" @click="page = 1; ListFn()">筛选</button>
					<button class="sf_but2" type="primary" @click="page = 1; AllMediaTab()">重置筛选</button>
					<collect :is_collect="is_collect" @collectShow="collectShow" />
					<mediaWhiteList />

				</div>
			</div>
		</div>
		<table class="table_1">
			<thead>
				<td>媒介名称</td>
				<td>直发价格</td>
				<td>转发价格</td>
				<td>行业类型</td>
				<td>所属区域</td>
				<td>参考粉丝数</td>
				<!-- <td>截稿时间</td>
				<td>出稿速度</td> -->
				<td>可发内链</td>
				<td>可发联系方式</td>
				<td>V认证</td>
				<td>备注(仅供参考)</td>
				<td>操作</td>
			</thead>
			<tbody>
				<tr v-for="(item, index) in tableData" :key="index">
					<td>
						<td_remark :text="item.title" />
					</td>
					<td>
						<button :class="item.check == 'direct_price' ? 'priceActive' : 'price'"
							@click.stop="onPitch(index, 'direct_price')">￥{{
		item.direct_price.toFixed(2)
	}}
						</button>
					</td>
					<td>
						<button :class="item.check == 'member_forward_price' ? 'priceActive' : 'price'"
							@click.stop="onPitch(index, 'member_forward_price')">￥{{
		item.member_forward_price.toFixed(2)
	}}
						</button>
					</td>
					<td>{{ item.industry_classify_title }}</td>
					<td>{{ item.area_title }}</td>
					<td>{{ item.fans_num }}</td>
					<!-- <td>{{ item.stop_draft_time == 0 ? '无' : item.stop_draft_time + ':00' }}</td>
					<td>{{
						item.draft_speed == 0
						? '不限'
						: item.draft_speed == 1
							? '1小时'
							: item.draft_speed == 2
								? '2小时'
								: item.draft_speed == 3
									? '3小时'
									: item.draft_speed == 4
										? '4小时'
										: item.draft_speed == 5
											? '当天'
											: '次日'
					}}</td> -->
					<td :style="{ color: item.is_pub_link == 1 ? '#55C964' : '#FF0000' }">
						{{ item.is_pub_link == 0 ? '否' : '是' }}
					</td>
					<td :style="{ color: item.is_pub_contact == 1 ? '#55C964' : '#FF0000' }">
						{{ item.is_pub_contact == 0 ? '否' : '是' }}
					</td>
					<td>
						{{ item.is_v_auth == 0 ? '否' : item.is_v_auth == 1 ? '是' : item.is_v_auth == 2 ? '蓝V认证'
		:
		item.is_v_auth == 3 ?
			'黄V认证' : '红V认证' }}
					</td>
					<td>
						<td_remark :text="item.remark" />
					</td>
					<td>
						<div class="collect_box flex">
							<p @click.stop="jumpLink(item)">查看案例</p>
							<!-- v-if="item.collect == 0"  -->
							<p v-if="item.is_collect == 0" class="collect" @click.stop="collectGet(item)">收藏</p>
							<!-- v-if="item.collect == 1" -->
							<p v-if="item.is_collect == 1" class="collect2" @click.stop="collectClose(item)">已收藏
							</p>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
		<!-- 分页器 -->
		<div class="paging_box">
			<pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page" />
		</div>
		<!-- 购物车 -->
		<div v-show="shopShow == false" class="shopping_car">
			<div class="car" @click.stop="shopShow = true">
				<p>{{ CarList.length }}</p>
			</div>
		</div>
		<div :class="shopShow ? 'shop_box' : 'shop_none_box'">
			<div class="title flex" style="justify-content: space-between; align-items: center">
				<h3 class="carTitle">已选媒介: {{ CarList.length }}</h3>
				<el-button v-show="CarList.length != 0" type="primary" @click="empty()">清空已选媒介</el-button>
				<span class="close" @click="shopShow = false"></span>
			</div>
			<ul class="listbox" v-show="CarList.length != 0">
				<li v-for="(item, index) in CarList" :key="index">
					<!-- <img :src="item.logo" alt="" /> -->
					<div>
						<td_remark :text="item.title" />
					</div>
					<p class="price">{{ item.check == 'direct_price' ? `直发价格:￥${item.direct_price.toFixed(2)}` :
		`转发价格:￥${item.member_forward_price.toFixed(2)}` }}</p>

					<span class="deleteIcon" @click="detale(item)"></span>
				</li>
			</ul>
			<el-empty v-show="CarList.length == 0" description="您还没有选择资源赶紧去看看心仪的资源吧"></el-empty>
			<div class="bottom_box">
				<div class="flex" style="justify-content: space-between; margin-bottom: 1rem">
					<p style="font-size: 1.4rem; color: #999">合计：</p>
					<p class="totalMoney">
						￥<span>{{ totalMoney ? totalMoney : '0.00' }}</span>
					</p>
				</div>
				<button class="put_but" @click="putFn">立即投放</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import Nav from '@/components/MediaNav.vue'
export default {
	name: '',
	computed: {
		...mapState(['Media', 'MediaNav', 'TypeItem', 'ActiveChild']),
		totalMoney: function () {
			var n = 0;
			for (var i = 0; i < this.CarList.length; i++) {
				if (this.CarList[i].check == 'direct_price') {
					n += this.CarList[i].direct_price;
				} else if (this.CarList[i].check == 'member_forward_price') {

					n += this.CarList[i].member_forward_price;
				}
			}
			return n.toFixed(2);
		}
	},
	components: {
		Nav,
	},
	created() { },
	mounted() {

		// if (this.$store.state.shopObj.length != 0) {
		// 	this.CarList = JSON.parse(JSON.stringify(this.$store.state.shopObj))
		// 	console.log(this.CarList);
		// }
		setTimeout(() => {
			for (const i in this.$store.state.Media) {
				if (this.$store.state.Media[i].id == 5) {
					this.ChannelList = this.$store.state.Media[i].industry_list
					this.TerritoryList = this.$store.state.Media[i].area_list
					this.FansNumList = this.$store.state.Media[i].fans_list
					this.VAuthList = this.$store.state.Media[i].is_v_auth
					this.MoneyList = this.$store.state.Media[i].price_range
					this.ForwardMoneyList = this.$store.state.Media[i].price_range
					this.officialList = this.$store.state.Media[i].official_list
					this.SpecialList = this.$store.state.Media[i].special_list
					this.SortList = this.$store.state.Media[i].order_list
				}
			}
			this.user_info = this.$util.getLocalStorage('user_info')

			this.ListFn()
		}, 200)
	},
	data() {
		return {
			user_info: {},
			// 频道类型
			ChannelType: {
				id: '',
				title: '不限',
			},
			ChannelList: [],
			//所属区域
			TerritoryType: {
				id: 0,
				title: '不限',
			},
			TerritoryList: [],
			// 粉丝数
			FansNumType: {
				id: 0,
				title: '不限',
			},
			FansNumList: [],
			// V认证
			VAuthType: {
				id: -1,
				title: '不限',
			},
			VAuthList: [],
			// 直发价格
			MoneyType: {
				id: 0,
				title: '不限',
			},
			MoneyList: [],
			// 转发区间
			ForwardMoneyType: {
				id: 0,
				title: '不限',
			},
			// 官方非官方
			officialType: '不限',
			officialList: [],
			ForwardMoneyList: [],
			// 特殊类目
			SpecialType: '不限',
			SpecialList: [],
			// 排序
			SortType: {
				id: 0,
				title: '默认',
			},
			SortList: [],
			MoneyState: 0, 			// 直发价格
			ForwardMoneyState: 0, 	// 转发价格
			/*-----------------媒介筛选------------------------*/
			mediaName: '', //媒介名称,
			linkList: ['是', '否'], //可发链接列表
			link: '', //可发链接
			relation: '', //可发联系方式
			/*数据表格*/
			tableData: [],
			CarList: [],
			// 购物车任务栏切换
			shopShow: false,
			fullscreenLoading: false,
			total_page: 0, //分页总页数
			count: 0, //总条数
			page: 1,
			is_collect: 0,

		}
	},
	methods: {
		pageChange(pageVal) {
			this.page = pageVal
			this.ListFn()
		},
		StateShow(item, index) {
			/*
			每次点击时需要重置价格-电脑权重-移动权重的状态,在每个判断条件中需做重置
			*/
			this.SortType = {
				id: index,
				title: item,
			}
			switch (item) {
				case '默认':
					this.MoneyState = 0
					this.ForwardMoneyState = 0
					this.SortType.id = 0
					break

				case '直发价格':
					this.ForwardMoneyState = 0
					if (this.MoneyState == 0) {
						this.MoneyState = 1
					} else if (this.MoneyState == 1) {
						this.MoneyState = 2
					} else {
						this.MoneyState = 1
					}
					break

				case '转发价格':
					this.MoneyState = 0
					if (this.ForwardMoneyState == 0) {
						this.ForwardMoneyState = 1
					} else if (this.ForwardMoneyState == 1) {
						this.ForwardMoneyState = 2
					} else {
						this.ForwardMoneyState = 1
					}
					break

				default:
					break
			}
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		onPitch(index, type) {
			this.shopShow = true
			console.log('tableDate:', this.tableData[index].check, type);
			// this.tableData[index].check = type
			const result = this.CarList.every(item => item.id !== this.tableData[index].id);
			if (result == true) {
				this.tableData[index].check = type
				this.CarList.push(this.tableData[index])
			} else {
				this.CarList.forEach((item, i) => {
					if (this.CarList[i].id == this.tableData[index].id && this.CarList[i].check != type) {
						console.log(this.CarList[i].check);
						this.tableData[index].check = type
						this.CarList[i].check = type
					} else if (this.CarList[i].id == this.tableData[index].id && this.tableData[index].check == type) {
						console.log(2);
						this.CarList.splice(i, 1)
						this.tableData[index].check = ''
					}
				})
			}
		},
		// 清空购物车
		empty() {
			this.$confirm('是否清空已选媒介?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					this.fullscreenLoading = true
					// setTimeout(() => {
					this.CarList = []
					this.shopShow = false
					// this.toggleSelection()
					this.tableData.forEach((item, index) => {
						if (item.check) {
							return (item.check = false)
						}
					})
					this.fullscreenLoading = false
					// }, 500)
					this.$message({
						type: 'success',
						message: '已清空已选媒介',
					})
				})
				.catch(() => {
					this.$message({
						type: 'warning',
						message: '已删除',
					})
				})
		},
		createBall(left, top) {
			const bar = document.createElement('ball')
			bar.style.position = 'fixed'
			bar.style.left = left + 'px'
			bar.style.top = top + 'px'
			bar.style.width = '20px'
			bar.style.height = '20px'
			bar.style.borderRadius = '50%'
			bar.style.backgroundColor = 'red'
			bar.style.transition =
				'left 1s linear, top 1s cubic-bezier(0.5, -0.5, 1, 1)'
			document.body.appendChild(bar)
			setTimeout(() => {
				const x = document.body.clientWidth * 1.5
				const y = document.body.clientHeight * 1 - 500
				bar.style.top = y + 'px'
				bar.style.left = x + 'px'
			}, 0)
			bar.ontransitionend = function () {
				this.remove()
			}
		},
		// 结算
		goClose(item) {
			this.$router.push({
				path: '/close',
				query: {},
			})
		},
		// 频道类型切换
		ChannelShow(item, index) {
			this.ChannelType = {
				title: item,
				id: index ? parseInt(index) : 0,
			}
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 所属区域类型切换
		TerritoryShow(item, index) {
			this.TerritoryType = {
				title: item,
				id: index ? parseInt(index) : 0,
			}
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 粉丝切换
		FansNumShow(item, index) {
			this.FansNumType = {
				title: item,
				id: index ? parseInt(index) : 0,
			}
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// V认证切换
		VAuthShow(item, index) {
			this.VAuthType = {
				title: item,
				id: index ? parseInt(index) : 0,
			}
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 价格区间切换
		MoneyShow(item, index) {
			this.MoneyType = {
				title: item,
				id: index ? parseInt(index) : 0,
			}
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 是否为官方媒介
		isOfficialShow(item) {
			this.officialType = item
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 价格区间切换
		ForwardMoneyShow(item, index) {
			this.ForwardMoneyType = {
				title: item,
				id: index ? parseInt(index) : 0,
			}
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 特殊类目切换
		SpecialShow(item) {
			this.SpecialType = item
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 切换页
		currentTab(val) {
			this.page = val
			this.ListFn()
			this.TabCheck()
		},
		// 上一页
		prevClick() {
			this.page -= 1
			this.ListFn()
			this.TabCheck()
		},
		// 下一页
		// next-click
		nextClick() {
			this.page += 1
			this.ListFn()
			this.TabCheck()
		},
		// 是否切换收藏列表
		collectShow(id) {
			this.is_collect = id
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 获取列表数据
		ListFn() {
			let data = {}
			data.page = this.page
			data.limit = 20
			data.category_id = 5
			if (this.ChannelType.id != 0) {
				data.industry_id = this.ChannelType.id
			}
			if (this.TerritoryType.id != 0) {
				data.area_id = this.TerritoryType.id
			}
			if (this.FansNumType.id != 0) {
				data.fans_num = this.FansNumType.id
			}
			if (this.VAuthType.id >= 0) {
				data.is_v_auth = this.VAuthType.id
			}
			if (this.MoneyType.id != 0) {
				data.price_id = this.MoneyType.id
			}
			if (this.ForwardMoneyType.id != 0) {
				data.forward_price_id = this.ForwardMoneyType.id
			}
			if (this.officialType == '非官方媒介') {
				data.is_official = 0
			} else if (this.officialType == '官方媒介') {
				data.is_official = 1
			}
			if (this.SpecialType == '焦点图') {
				data.is_pub_image = 1
			} else if (this.SpecialType == '文字链') {
				data.is_pub_url = 1
			} else if (this.SpecialType == '周末可发') {
				data.is_pub_weekend = 1
			} else if (this.SpecialType == '可发视频') {
				data.is_pub_video = 1
			}
			if (this.SortType.title == '直发价格') {
				data.sort = 'member_price'
				if (this.MoneyState == 1) {
					data.order = 'asc'
				} else {
					data.order = 'desc'
				}
			} else if (this.SortType.title == '转发价格') {
				data.sort = 'member_forward_price'
				if (this.ForwardMoneyState == 1) {
					data.order = 'asc'
				} else {
					data.order = 'desc'
				}
			}
			if (this.mediaName !== '') {
				data.title = this.mediaName
			}
			if (this.link != '' && this.link == '是') {
				data.is_pub_url = 1
			} else if (this.link !== '' && this.link == '否') {
				data.is_pub_url = 0
			}
			if (this.relation !== '' && this.relation == '是') {
				data.is_pub_contact = 1
			} else if (this.relation !== '' && this.relation == '否') {
				data.is_pub_contact = 0
			}
			if (this.is_collect) {
				data.collect = this.is_collect
			}
			this.fullscreenLoading = true
			this.curlGet('/api/medium/list', data).then((res) => {
				if (res.data.code) {
					this.tableData = res.data.data.list
					this.count = res.data.data.count
					this.total_page = res.data.data.total_page
					this.tableData.forEach((item, index) => {
						this.$set(this.tableData[index], 'check', '')
						this.CarList.forEach((el, i) => {
							if (el.check && item.id == el.id) {
								this.$set(this.tableData[index], 'check', el.check)
							}
						})
					})
					this.shopDate(this.tableData, this.$store.state.shopObj, this.CarList)
				}
				this.fullscreenLoading = false
			})
		},
		// 重置选择
		AllMediaTab() {
			this.ChannelType = {
				id: 0,
				title: '不限'
			}
			this.TerritoryType = {
				id: 0,
				title: '不限'
			}
			this.VAuthType = {
				id: 0,
				title: '不限'
			}
			this.FansNumType = {
				id: 0,
				title: '不限'
			}
			this.MoneyType = {
				id: 0,
				title: '不限'
			}
			this.ForwardMoneyType = {
				id: 0,
				title: '不限'
			}
			this.SpecialType = '不限'
			this.SortType = '默认'
			this.officialType = '不限'
			this.relation = ''
			this.link = ''
			this.SortType = { title: '默认', type: '' },
				this.mediaName = ''
			this.page = this.pageChangeOne()
			this.ListFn()

		},
		// 筛选
		screen() {
			this.ListFn()
		},

		// 判断切换数据时是否选中该条数据
		TabCheck() {
			this.fullscreenLoading = true
			setTimeout(() => {
				this.tableData.forEach((item, index) => {
					this.CarList.forEach((e, i) => {
						if (e.id == item.id && !item.check) {
							return (item.check = true)
						} else {
							return (item.check = false)
						}
					})
				})
				this.fullscreenLoading = false
			}, 500)
		},
		// 删除
		detale(element) {
			this.CarList.forEach((el, i) => {
				if (el.id == element.id) {
					this.CarList.splice(i, 1)
				}
			})
			// this.CarList.splice(index,1)
			this.tableData.forEach((item, index) => {
				if (item.id == element.id) {
					this.tableData[index].check = ''
				}
			})
		},
		// 价格总和

		// 立即投放
		putFn() {
			if (this.CarList.length == 0) {
				return this.$message({
					type: 'warning',
					message: '请选择套餐',
				})
			} else if (!this.$user_info) {
				this.$message({
					type: 'warning',
					message: '请登录',
				})
			} else {
				this.fullscreenLoading = true
				this.CarList.forEach((item, index) => {
					this.CarList[index].price = this.CarList[index][this.CarList[index].check]
					console.log(this.CarList[index]);
				})
				setTimeout(() => {
					this.fullscreenLoading = false
					this.$store.commit('shopPush', this.CarList)
					if (this.$store.state.fillActive == false) {

						this.$router.push('/user/SetMenu/ManuscriptPreview')
					} else {
						this.$router.push({
							path: '/user/SetMenu/fill?id=5',
						})
					}
				}, 500)
			}
		},
		// 收藏
		collectGet(item) {
			if (!this.user_info) {
				this.$message({
					type: 'warning',
					message: '请登录',
					offset: 90,
				})
			} else {
				this.curlGet('/api/medium/collect', {
					mid: item.id,
					style: 1
				}).then(res => {
					if (res.data.code) {
						this.$message({
							message: '收藏成功',
							type: 'success'
						})
						this.ListFn()
					}
				})
			}
		},
		// 取消收藏
		collectClose(item) {
			this.$confirm('是否取消收藏?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.curlGet('/api/medium/collect', {
					mid: item.id,
					style: 1
				}).then(res => {
					if (res.data.code) {
						this.$message({
							type: 'success',
							message: '取消收藏成功!'
						});
						this.ListFn()
					}
				})

			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消'
				});
			});

		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../scss/Media.scss';
@import '../../scss/overText.scss';
</style>